import { listingQuery } from "@/api/listings";
import { queryClient } from "@/api/utils";
import { checkIdPrefix } from "@/utils/id";
import { createRoute } from "@tanstack/react-router";
import { marketplaceRoute } from "../../+route";

export const listingRoute = createRoute({
  path: "listings/$listingId",
  getParentRoute: () => marketplaceRoute,
  loader: async ({ params: { listingId } }) => {
    checkIdPrefix(listingId, "LISTING");
    await queryClient.ensureQueryData(listingQuery(listingId));
  },
}).lazy(() => import("./+route.lazy").then(m => m.route));
